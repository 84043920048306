var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ha-modal',_vm._g({staticClass:"ModalContributionSelection",attrs:{"data-testid":"contribution-modal"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n    "+_vm._s(_vm.$t('modal.contributionSelection.title'))+"\n  ")]},proxy:true},{key:"body",fn:function(){return [_c('p',[_vm._v("\n      "+_vm._s(_vm.$t(`modal.contributionSelection.intro_1${_vm.isCheckout ? '_checkout' : ''}`))+"\n    ")]),_vm._v(" "),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t(`modal.contributionSelection.intro_2${_vm.isCheckout ? '_checkout' : ''}`)))])]),_vm._v(" "),_c('div',{staticClass:"Tip"},[(_vm.tipData.tipSuggestion)?_c('VoluntaryContributionIllustration',{attrs:{"tip-suggestion":_vm.tipData.tipSuggestion,"tip-selected":_vm.tipSelectedInCents}}):_vm._e(),_vm._v(" "),_c('h3',[_vm._v(_vm._s(_vm.$t('modal.contributionSelection.contributeUpTo')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipSelected),expression:"tipSelected"}],attrs:{"min":_vm.minTip,"max":_vm.maxTipCheckoutIncluded,"step":"0.01","type":"range","data-ux":`Forms_${_vm.metadata.type}${
          _vm.isWidget ? 'Widget' : ''
        }_Modal_ContributionSelection_ContributionCursor`},domProps:{"value":(_vm.tipSelected)},on:{"change":_vm.onUpdateTip,"__r":function($event){_vm.tipSelected=$event.target.value}}}),_vm._v(" "),_c('validation-provider',{attrs:{"rules":"min_value:0","name":"contribution"},scopedSlots:_vm._u([{key:"default",fn:function({ valid, errors }){return [_c('ha-form-field',{attrs:{"is-valid":valid},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v(" "+_vm._s(errors[0])+" ")]},proxy:true}],null,true)},[_c('div',{staticClass:"Tip--Input"},[_c('ha-input',{attrs:{"type":"number","step":"0.01","is-valid":!valid ? valid : null,"data-ux":`Forms_${_vm.metadata.type}${
                _vm.isWidget ? 'Widget' : ''
              }_Modal_ContributionSelection_ContributionAmount`,"data-testid":"input-cv-amount"},on:{"input":_vm.onUpdateTip,"keydown":_vm.checkEntry},model:{value:(_vm.tipSelected),callback:function ($$v) {_vm.tipSelected=$$v},expression:"tipSelected"}}),_vm._v(" "),_c('span',[_vm._v("€")])],1)])]}}])}),_vm._v(" "),(!_vm.isCheckout)?_c('ha-checkbox',{staticClass:"Tip--Checkbox",attrs:{"name":"dontTip","data-ux":`Forms_${_vm.metadata.type}${
          _vm.isWidget ? 'Widget' : ''
        }_Modal_ContributionSelection_NoContribution`,"data-testid":"checkbox-no-contribution"},on:{"change":_vm.onUpdateCheckbox},model:{value:(_vm.isTipDisabled),callback:function ($$v) {_vm.isTipDisabled=$$v},expression:"isTipDisabled"}},[_vm._v("\n        "+_vm._s(_vm.$t('modal.contributionSelection.noContribution'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.showCatchUp)?_c('div',{staticClass:"Tip--CatchUp",attrs:{"data-testid":"retake-wording"}},[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('modal.contributionSelection.catchUpTips.yourSupportTo', [_vm.organization.name]))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('modal.contributionSelection.catchUpTips.yourSupportTo2'))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('modal.contributionSelection.catchUpTips.yourSupportTo3'))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('modal.contributionSelection.catchUpTips.helpPayBankfee', [_vm.bankTransactionCost]))+"\n        ")])]):_vm._e()],1)]},proxy:true},{key:"footer",fn:function(){return [(!_vm.showCatchUp)?_c('ha-button',{attrs:{"disabled":_vm.invalidTipValue,"data-ux":`Forms_${_vm.metadata.type}${
        _vm.isWidget ? 'Widget' : ''
      }_Modal_ContributionSelection_SaveContribution`,"data-testid":"button-save"},on:{"click":function($event){return _vm.saveTipSelected(_vm.tipSelectedInCents)}}},[_vm._v("\n      "+_vm._s(_vm.$t('button.save'))+"\n    ")]):_c('div',{staticClass:"Tip--CatchUp--Buttons"},[_c('ha-button',{attrs:{"variant":"outline","data-ux":`Forms_${_vm.metadata.type}${
          _vm.isWidget ? 'Widget' : ''
        }_Modal_ContributionSelection_SaveContribution`,"data-testid":"button-no-retake"},on:{"click":function($event){return _vm.saveTipSelected(0, true)}}},[_vm._v("\n        "+_vm._s(_vm.$t('modal.contributionSelection.catchUpTips.noThankYou'))+"\n      ")]),_vm._v(" "),_c('ha-button',{attrs:{"data-ux":`Forms_${_vm.metadata.type}${
          _vm.isWidget ? 'Widget' : ''
        }_Modal_ContributionSelection_SaveContribution`,"data-testid":"button-validate-retake"},on:{"click":function($event){return _vm.saveTipSelected(_vm.bankTransactionCostInCents, true)}}},[_vm._v("\n        "+_vm._s(_vm.$t('modal.contributionSelection.catchUpTips.addTips', [_vm.bankTransactionCost]))+"\n      ")])],1)]},proxy:true}])},_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }
import { watch, computed, Ref } from 'vue'
import { DateOperator, functions } from '@ha/helpers'
import { useSsrState } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import { useStore, useRoute } from '@nuxtjs/composition-api'
import useFormType from '@/composables/useFormType'
import { UserData } from '../users/users.interface'
import { Payer } from './payer.interface'
import { Cart } from '../carts/carts.interface'
import { CustomField } from '../custom-fields/customField.interface'
import { CollectionOf } from '~/types/common.interface'

const personalInformations: Ref<UserData> = useSsrState('personalInformations', () => ({}))
const customFields: Ref<CollectionOf<CustomField>> = useSsrState('customFields', () => ({}))
const keyBirthDate: Ref<string> = useSsrState('keyBirthDate', functions.generateRandomId)

export default () => {
  const store = useStore()
  const route = useRoute()
  const { user, cart, form } = useStoreData()
  const { isCheckout, isDonation, isCrowdfunding } = useFormType()

  const cartInfo = computed((): Cart => {
    return isCheckout.value
      ? store.getters['checkout/getCart'](route.value.params.cartId)
      : cart.value
  })

  const payerInformationPrefilled = computed(
    (): Payer => store.getters['payer/getPrefilledPayer'](cart.value?.id)
  )

  const displayCheckboxIsOrganism = computed(() => {
    if (!isDonation.value && !isCrowdfunding.value) return true
    if (!form.value?.allowIndividualPayer && !form.value?.allowOrganismPayer) return true
    return form.value.allowIndividualPayer && form.value.allowOrganismPayer
  })
  const allowOnlyOrganism = computed(() => {
    if (!isDonation.value && !isCrowdfunding.value) return false
    if (!form.value?.allowIndividualPayer && !form.value?.allowOrganismPayer) return false
    return !form.value.allowIndividualPayer && form.value.allowOrganismPayer
  })
  const allowOnlyIndividual = computed(() => {
    if (!isDonation.value && !isCrowdfunding.value) return false
    if (!form.value?.allowIndividualPayer && !form.value?.allowOrganismPayer) return false
    return form.value.allowIndividualPayer && !form.value.allowOrganismPayer
  })

  const payerCartInfo = computed(() => cartInfo.value?.payer)

  const getUserBirthDate = (): string => {
    if (payerCartInfo.value?.dateOfBirth) {
      return new DateOperator(payerCartInfo.value?.dateOfBirth).format('DD/MM/YYYY')
    }
    return user.value?.birthDate
      ? new DateOperator(user.value?.birthDate).format('DD/MM/YYYY')
      : null
  }

  // First we want info from cart then from prefilledPayer
  // to avoid override if user modify their info in payerStep
  const firstNamePayer = computed(
    () => payerCartInfo.value?.firstName || payerInformationPrefilled.value?.firstname
  )
  const lastNamePayer = computed(
    () => payerCartInfo.value?.lastName || payerInformationPrefilled.value?.lastname
  )
  const emailPayer = computed(
    () => payerCartInfo.value?.email || payerInformationPrefilled.value?.email
  )

  const getUserData = (): UserData => ({
    firstName: firstNamePayer.value ?? user.value?.firstName,
    lastName: lastNamePayer.value ?? user.value?.lastName,
    email: emailPayer.value ?? user.value?.email,
    birthDate: getUserBirthDate(),
    address: payerCartInfo.value?.place?.address ?? user.value?.address,
    zipCode: payerCartInfo.value?.place?.zipCode ?? user.value?.zipCode,
    city: payerCartInfo.value?.place?.city ?? user.value?.city,
    country: payerCartInfo.value?.place?.country ?? (user.value?.country || 'FRA'),
    isCompany: !!payerCartInfo.value?.companyName || !!user.value?.companyName,
    companyName: payerCartInfo.value?.companyName ?? user.value?.companyName,
    companySiren: payerCartInfo.value?.companySiren ?? user.value?.companySiren,
    companyLegalStatus: payerCartInfo.value?.companyLegalStatus ?? user.value?.companyLegalStatus
  })

  const updateCustomFields = (dataToUpdate: Partial<CustomField>) => {
    Object.assign(customFields.value, dataToUpdate)
  }
  const updatePersonalInformations = (dataToUpdate: Partial<UserData>) => {
    if (dataToUpdate.isCompany === false) {
      personalInformations.value.companyName = null
      personalInformations.value.companySiren = null
      personalInformations.value.companyLegalStatus = null
    }
    Object.assign(personalInformations.value, dataToUpdate)
  }
  const updatePersonalInformationsWithUserData = () => {
    personalInformations.value = getUserData()
  }
  const setPayerPersonalInformations = () => {
    customFields.value = {}
    updatePersonalInformationsWithUserData()
    watch(user, () => {
      if (user.value?.birthDate !== personalInformations.value.birthDate) {
        keyBirthDate.value = functions.generateRandomId()
      }
      updatePersonalInformationsWithUserData()
    })
  }

  return {
    personalInformations,
    customFields,
    keyBirthDate,
    displayCheckboxIsOrganism,
    allowOnlyOrganism,
    allowOnlyIndividual,
    updatePersonalInformations,
    updateCustomFields,
    setPayerPersonalInformations
  }
}

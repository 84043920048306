import { computed, ref } from 'vue'
import { functions } from '@ha/helpers'
import { useSsrState } from '@ha/components'
import { getTipSuggestion } from '@/helpers/formUtils'
import { useStore } from '@nuxtjs/composition-api'
import useStoreData from '@/composables/useStoreData'

const tipSelectedInEuros = useSsrState('tipSelectedInEuros', () => 0)
const tipSuggested = useSsrState('tipSuggested', () => ({ min: 0, default: 0, max: 0 }))

export default () => {
  const store = useStore()
  const { storeRouteParams } = useStoreData()

  const displayModalContributionExplication = ref(false)
  const displayModalContributionSelection = ref(false)

  const tipSelected = computed(() => functions.convertToCents(tipSelectedInEuros.value))
  const tipSuggestedInEuros = computed(() => ({
    min: parseFloat(functions.convertToEuros(tipSuggested.value.min)).toFixed(2),
    default: parseFloat(functions.convertToEuros(tipSuggested.value.default)).toFixed(2),
    max: parseFloat(functions.convertToEuros(tipSuggested.value.max)).toFixed(2)
  }))

  const formatTipSelectedInEuros = tipToFormat => {
    return functions.convertToEuros(functions.convertToCents(tipToFormat))
  }

  const updateTipSelectedInEuros = async (newTipInEuros, isPaymentTermsEnabled = false) => {
    if (isPaymentTermsEnabled) {
      const cart = store.getters['carts/getCart'](storeRouteParams.value)
      await store.dispatch('carts/putTip', [
        functions.convertToCents(newTipInEuros),
        storeRouteParams.value,
        cart.id
      ])
    }

    tipSelectedInEuros.value = formatTipSelectedInEuros(newTipInEuros)
  }

  const setTipSuggested = (suggestions, setTipSelected = true) => {
    tipSuggested.value = suggestions

    if (setTipSelected) {
      tipSelectedInEuros.value = parseFloat(
        formatTipSelectedInEuros(tipSuggestedInEuros.value.default)
      ).toFixed(2)
    }
  }

  const setTipSuggestedFromTierAmount = tierAmount => {
    const calculatedTipSuggestion = getTipSuggestion(tierAmount, 1)
    tipSuggested.value = {
      min: calculatedTipSuggestion.tipSuggestionMin,
      default: calculatedTipSuggestion.tipSuggestion,
      max: calculatedTipSuggestion.tipSuggestionMax
    }
    tipSelectedInEuros.value = formatTipSelectedInEuros(tipSuggestedInEuros.value.default)
  }

  const isTipable = computed(() => tipSelected.value > 0)

  const openContributionEditionModal = () => {
    displayModalContributionSelection.value = true
  }
  const openContributionExplicationModal = () => {
    displayModalContributionExplication.value = true
  }
  const closeContributionEditionModal = () => {
    displayModalContributionSelection.value = false
  }
  const closeContributionExplicationModal = () => {
    displayModalContributionExplication.value = false
  }

  return {
    displayModalContributionExplication,
    displayModalContributionSelection,
    tipSelectedInEuros,
    tipSelected,
    tipSuggested,
    tipSuggestedInEuros,
    isTipable,
    setTipSuggested,
    setTipSuggestedFromTierAmount,
    updateTipSelectedInEuros,
    openContributionEditionModal,
    openContributionExplicationModal,
    closeContributionEditionModal,
    closeContributionExplicationModal
  }
}

<script setup>
import { ref } from 'vue'
import { HaButton } from '@ha/components'
import ModalLogin from '@/components/users/ModalLogin.vue'

const displayModalLogin = ref(false)
</script>

<template>
  <div>
    <p class="Block-Personal-Informations--Login" data-test="payer-personal-informations-login">
      <span>{{ $t('campaign.step.payer.existingAccount') }}</span>
      <HaButton variant="link" @click="displayModalLogin = true">
        {{ $t('campaign.step.payer.login') }}
      </HaButton>
    </p>
    <ModalLogin
      v-if="displayModalLogin"
      @close="displayModalLogin = false"
      @login="displayModalLogin = false"
    />
  </div>
</template>

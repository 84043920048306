import { computed } from 'vue'
import { useSsrState } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'

const legalState = useSsrState('legalState', () => ({
  gdrpConsent: false,
  tipConsent: false,
  cgvConsent: false
}))

export default () => {
  const { form } = useStoreData()
  const { tipSelected } = useVoluntaryContribution()

  const setLegalCheckboxes = () => {
    legalState.gdrpConsent = false
    legalState.tipConsent = false
    legalState.cgvConsent = false
  }

  const isAllLegalCheckboxesChecked = computed(
    () =>
      legalState.value.gdrpConsent &&
      (tipSelected.value > 0 ? legalState.value.tipConsent : true) &&
      (form.value?.conditionsAndTermsFile ? legalState.value.cgvConsent : true)
  )

  return {
    isAllLegalCheckboxesChecked,
    setLegalCheckboxes,
    legalState
  }
}

<script setup>
import { computed, ref } from 'vue'
import { ValidationProvider } from 'vee-validate'
import { HaButton, HaFormField, HaInput } from '@ha/components'
import Datepicker from '@/components/globals/form-fields/Datepicker.vue'
import useStoreData from '@/composables/useStoreData'
import useLogin from '@/composables/useLogin'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import useFormType from '@/composables/useFormType'
import useAbnormalEmailControl from '@/composables/useAbnormalEmailControl'
import useTracking from '@/composables/useTracking'

defineProps({
  selectedTier: {
    type: Object
  }
})

const emit = defineEmits(['logout'])

const { isCrowdfunding, isDonation, isCheckout, isSale } = useFormType()
const { user, checkoutCart } = useStoreData()
const { onLogout, isDisconnecting } = useLogin()
const { personalInformations, keyBirthDate } = usePayerPersonalInformations()
const { abnormalEmailWarning, hasAbnormalValues, emailFieldValidityState } =
  useAbnormalEmailControl(computed(() => personalInformations.value.email))
const { trackingMethods } = useTracking()
const { onFirstClick } = trackingMethods

const hasUser = computed(() => !!user.value?.userId)
const birthDateRules = computed(() => `REQUIRED|DATE|birthdate${isDonation ? '|legal_age' : ''}`)
const firstNameValidationRules = computed(() => {
  return `REQUIRED|NAME_CHARS|NAME_NO_NUMBERS|NAME_LATIN|NAME_CONSECUTIVE_CHARS|NAME_VOWELS|NAME_LENGTH|NAME_DIFFERENT:${personalInformations.lastName}|NAME_IS_FORBIDDEN|NAME_CONTAINS_FORBIDDEN|max_length:50`
})
const lastNameValidationRules = computed(() => {
  return `REQUIRED|NAME_CHARS|NAME_NO_NUMBERS|NAME_LATIN|NAME_CONSECUTIVE_CHARS|NAME_VOWELS|NAME_LENGTH|NAME_DIFFERENT:${personalInformations.firstName}|NAME_IS_FORBIDDEN|NAME_CONTAINS_FORBIDDEN|max_length:50`
})

const emailConfirmation = ref("")
const showEmailConfirmation = computed(() => !hasUser.value && !isCheckout.value)

const checkoutContainsDonation = computed(
  () => isCheckout.value && checkoutCart.value.containsDonation
)
const isSaleOrDonation = computed(() => isDonation.value || isSale.value)

const isBirthDateNeeded = computed(
  () => isCrowdfunding.value || isSaleOrDonation.value || checkoutContainsDonation.value
)

const trackFirstClick = () => {
  onFirstClick('First Interaction Campaign', {
    step: 0,
    step_name: 'Coordonnées'
  })
}

const logout = async () => {
  await onLogout()
  emit('logout')
}
</script>

<template>
  <div>
    <div class="Columns">
      <ValidationProvider
        v-slot="{ errors, valid, validated }"
        :rules="firstNameValidationRules"
        name="firstName"
        slim
      >
        <HaFormField :is-valid="validated ? valid : null">
          <template #label> {{ $t('campaign.field.firstName') }} * </template>
          <HaInput
            v-model="personalInformations.firstName"
            :is-valid="validated ? valid : null"
            :max-length="50"
            required
            hide-counter
            data-testid="input-firstname"
            @click="trackFirstClick"
          />
          <template #error>
            {{ errors?.[0] }}
          </template>
        </HaFormField>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid, validated }"
        :rules="lastNameValidationRules"
        name="lastName"
        slim
      >
        <HaFormField :is-valid="validated ? valid : null">
          <template #label> {{ $t('campaign.field.lastName') }} * </template>
          <HaInput
            v-model="personalInformations.lastName"
            :is-valid="validated ? valid : null"
            :max-length="50"
            required
            hide-counter
            data-testid="input-lastname"
            @click="trackFirstClick"
          />
          <template #error>
            {{ errors?.[0] }}
          </template>
        </HaFormField>
      </ValidationProvider>
    </div>
    <ValidationProvider
      v-slot="{ errors, valid, validated }"
      vid="email"
      rules="REQUIRED|EMAIL"
      name="email"
      class="Block-Personal-Informations--Email"
      slim
    >
      <HaFormField :is-valid="emailFieldValidityState(validated, valid)">
        <template #label> {{ $t('campaign.field.email') }} * </template>
        <HaInput
          v-model="personalInformations.email"
          :is-valid="validated ? valid : null"
          :disabled="hasUser"
          :max-length="250"
          required
          hide-counter
          data-testid="input-email"
          @click="trackFirstClick"
        />
        <template #error>
          <template v-if="errors?.length">{{ errors[0] }}</template>
          <template v-else-if="hasAbnormalValues"> {{ abnormalEmailWarning }}</template>
        </template>
      </HaFormField>
      <HaButton
        v-if="hasUser"
        class="Block-Personal-Informations--Email--Edit"
        variant="outline"
        :disabled="isDisconnecting"
        :loading="isDisconnecting"
        data-testid="button-update-email"
        @click="logout"
      >
        <fa icon="pencil-alt" />
      </HaButton>
    </ValidationProvider>
    <ValidationProvider
      v-if="showEmailConfirmation"
      v-slot="{ errors, valid, validated }"
      rules="REQUIRED|EMAIL_MATCH:@email"
      name="emailConfirmation"
      slim
    >
      <HaFormField :is-valid="validated ? valid : null">
        <template #label> {{ $t('campaign.field.emailConfirmation') }} * </template>
        <HaInput
          v-model="emailConfirmation"
          :is-valid="validated ? valid : null"
          :disabled="hasUser"
          :max-length="250"
          required
          hide-counter
          data-testid="input-email-confirmation"
        />
        <template #error>
            {{ errors?.[0] }}
          </template>
      </HaFormField>
    </ValidationProvider>
    <ValidationProvider
      v-if="isBirthDateNeeded"
      v-slot="{ errors, valid, validated }"
      :rules="birthDateRules"
      name="birthDate"
      class="Block-Personal-Informations--BirthDate"
      slim
    >
      <HaFormField :is-valid="validated ? valid : null">
        <template #label> {{ $t('campaign.field.birthday') }} * </template>
        <Datepicker
          :key="keyBirthDate"
          v-model="personalInformations.birthDate"
          :is-valid="validated ? valid : null"
          required
          hide-counter
          format="dd/MM/yyyy"
          placeholder="jj/mm/aaaa"
          data-testid="input-birthday-date"
          @click="trackFirstClick"
        />
        <template #error>
          {{ errors?.[0] }}
        </template>
      </HaFormField>
    </ValidationProvider>
  </div>
</template>

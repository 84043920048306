<script setup>
import { computed } from 'vue'
import { useRoute } from '@nuxtjs/composition-api'
import { ValidationProvider } from 'vee-validate'
import { HaFormField, HaInput, HaSelect } from '@ha/components'
import { functions, enum as enums } from '@ha/helpers'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import useTracking from '@/composables/useTracking'
import useFormType from '@/composables/useFormType'

defineProps({
  selectedTier: {
    type: Object
  }
})

const route = useRoute()
const { personalInformations } = usePayerPersonalInformations()
const { isCheckout } = useFormType()
const { trackingMethods } = useTracking()
const { onFirstClick } = trackingMethods

const countryOptions = computed(() => {
  // Available only for 'formulaires' -> donation
  const lang = route.value.name === 'EnglishRoute' ? 'en' : 'fr'
  return functions.getSortedCountries(enums.Country, lang).map(country => {
    return {
      text: country.label[lang],
      value: country.code3
    }
  })
})

const trackFirstClick = () => {
  onFirstClick('First Interaction Campaign', {
    step: 0,
    step_name: 'Coordonnées'
  })
}
</script>

<template>
  <div>
    <ValidationProvider v-slot="{ errors, valid, validated }" rules="REQUIRED" name="address" slim>
      <HaFormField :is-valid="validated ? valid : null">
        <template #label> {{ $t('campaign.field.address') }} * </template>
        <HaInput
          v-model="personalInformations.address"
          :is-valid="validated ? valid : null"
          :max-length="250"
          required
          hide-counter
          data-testid="input-address"
          @focus="trackFirstClick"
        />
        <template #error>
          {{ errors?.[0] }}
        </template>
      </HaFormField>
    </ValidationProvider>
    <div class="Columns">
      <ValidationProvider
        v-slot="{ errors, valid, validated }"
        rules="REQUIRED|ZIP_CODE"
        name="zipCode"
        slim
      >
        <HaFormField :is-valid="validated ? valid : null">
          <template #label> {{ $t('campaign.field.zipcode') }} * </template>
          <HaInput
            v-model="personalInformations.zipCode"
            :is-valid="validated ? valid : null"
            :max-length="10"
            required
            hide-counter
            data-testid="input-zipcode"
            @focus="trackFirstClick"
          />
          <template #error>
            {{ errors?.[0] }}
          </template>
        </HaFormField>
      </ValidationProvider>
      <ValidationProvider v-slot="{ errors, valid, validated }" rules="REQUIRED" name="city" slim>
        <HaFormField :is-valid="validated ? valid : null">
          <template #label> {{ $t('campaign.field.city') }} * </template>
          <HaInput
            v-model="personalInformations.city"
            :is-valid="validated ? valid : null"
            :max-length="250"
            required
            hide-counter
            data-testid="input-city"
            @focus="trackFirstClick"
          />
          <template #error>
            {{ errors?.[0] }}
          </template>
        </HaFormField>
      </ValidationProvider>
    </div>
    <ValidationProvider
      v-if="!isCheckout"
      v-slot="{ errors, valid, validated }"
      rules="REQUIRED"
      name="country"
      slim
    >
      <HaFormField :is-valid="validated ? valid : null">
        <template #label> {{ $t('campaign.field.country') }} * </template>
        <HaSelect
          v-model="personalInformations.country"
          :is-valid="validated ? valid : null"
          :options="countryOptions"
          required
          data-testid="select-country"
          @focus="trackFirstClick"
        />
        <template #error>
          {{ errors?.[0] }}
        </template>
      </HaFormField>
    </ValidationProvider>
  </div>
</template>

<template>
  <!-- eslint-disable max-len -->
  <div class="voluntary-contribution-illustration">
    <transition name="fade" mode="out-in">
      <p v-if="isNoDonation" class="voluntary-contribution-illustration__no-donation">
        {{ $t('modal.contributionSelection.noDonationDescription') }}
      </p>
      <svg
        v-else
        width="184"
        height="86"
        viewBox="0 0 184 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g :class="getClassesGroup('lower-donation')">
          <path
            d="M122.618 40.8955L119.084 67.6438C118.265 73.8386 122.624 79.524 128.819 80.3426L128.819 80.3426C135.013 81.1611 140.699 76.8027 141.517 70.6079L145.052 43.8596C145.87 37.6648 141.512 31.9794 135.317 31.1609L135.317 31.1609C129.122 30.3423 123.437 34.7007 122.618 40.8955Z"
            fill="#9EBCF9"
          />
          <path
            d="M137.457 43.7268C137.772 41.3428 137.085 39.2859 135.924 39.1324C134.762 38.9789 133.565 40.787 133.25 43.171C132.935 45.5549 133.622 47.6119 134.783 47.7654C135.945 47.9188 137.142 46.1107 137.457 43.7268Z"
            fill="white"
          />
          <path
            d="M131.825 46.175C132.987 46.3284 134.115 45.0408 134.345 43.299C134.575 41.5572 133.82 40.0208 132.658 39.8673C131.497 39.7138 130.369 41.0014 130.138 42.7432C129.908 44.4851 130.663 46.0215 131.825 46.175Z"
            fill="white"
          />
          <path
            d="M134.097 44.6842C134.498 44.7372 134.905 44.1543 135.007 43.3823C135.109 42.6104 134.867 41.9416 134.466 41.8887C134.066 41.8358 133.658 42.4187 133.556 43.1906C133.454 43.9626 133.696 44.6313 134.097 44.6842Z"
            fill="#2E2F5E"
          />
          <path
            d="M132.903 44.5265C133.303 44.5794 133.711 43.9965 133.813 43.2246C133.915 42.4526 133.672 41.7839 133.272 41.731C132.871 41.678 132.464 42.2609 132.362 43.0329C132.26 43.8048 132.502 44.4736 132.903 44.5265Z"
            fill="#2E2F5E"
          />
          <path
            d="M131.506 51.1699C131.43 51.7452 131.697 53.3147 132.273 53.3907C132.848 53.4667 133.513 52.0204 133.589 51.4452C133.665 50.8699 133.123 51.3835 132.548 51.3075C131.973 51.2315 131.582 50.5947 131.506 51.1699Z"
            fill="#2E2F5E"
          />
          <path
            d="M133.395 42.535C133.581 42.5596 133.752 42.4287 133.777 42.2426C133.801 42.0566 133.67 41.8859 133.484 41.8613C133.298 41.8367 133.127 41.9676 133.103 42.1536C133.078 42.3397 133.209 42.5104 133.395 42.535Z"
            fill="white"
          />
          <path
            d="M134.574 42.691C134.761 42.7156 134.931 42.5847 134.956 42.3987C134.98 42.2126 134.85 42.0419 134.663 42.0173C134.477 41.9927 134.307 42.1236 134.282 42.3097C134.258 42.4957 134.388 42.6664 134.574 42.691Z"
            fill="white"
          />
        </g>
        <g :class="getClassesGroup('equal-donation')">
          <path
            d="M44.8341 44.6503L48.3683 71.3986C49.1868 77.5934 44.8285 83.2788 38.6337 84.0973L38.6336 84.0973C32.4388 84.9159 26.7534 80.5575 25.9349 74.3627L22.4007 47.6144C21.5822 41.4196 25.9405 35.7342 32.1353 34.9156L32.1354 34.9156C38.3302 34.0971 44.0156 38.4555 44.8341 44.6503Z"
            fill="#4C40CF"
          />
          <path
            d="M29.9948 47.4814C29.6798 45.0974 30.3661 43.0405 31.5277 42.887C32.6893 42.7335 33.8863 44.5416 34.2013 46.9256C34.5162 49.3095 33.8299 51.3665 32.6684 51.5199C31.5068 51.6734 30.3098 49.8653 29.9948 47.4814Z"
            fill="white"
          />
          <path
            d="M35.6273 49.9296C34.4657 50.0831 33.3375 48.7955 33.1074 47.0537C32.8772 45.3119 33.6323 43.7755 34.7939 43.622C35.9555 43.4685 37.0837 44.7561 37.3139 46.4979C37.544 48.2397 36.7889 49.7762 35.6273 49.9296Z"
            fill="white"
          />
          <path
            d="M33.3553 48.439C32.9546 48.492 32.5472 47.9091 32.4452 47.1371C32.3432 46.3651 32.5853 45.6964 32.9859 45.6435C33.3865 45.5906 33.7939 46.1735 33.8959 46.9454C33.9979 47.7174 33.7559 48.3861 33.3553 48.439Z"
            fill="#2E2F5E"
          />
          <path
            d="M34.5498 48.2813C34.1492 48.3342 33.7417 47.7513 33.6397 46.9794C33.5377 46.2074 33.7798 45.5387 34.1804 45.4857C34.581 45.4328 34.9884 46.0157 35.0904 46.7877C35.1924 47.5596 34.9504 48.2283 34.5498 48.2813Z"
            fill="#2E2F5E"
          />
          <path
            d="M34.057 46.2898C33.871 46.3143 33.7003 46.1835 33.6757 45.9974C33.6511 45.8114 33.782 45.6406 33.968 45.6161C34.1541 45.5915 34.3248 45.7224 34.3494 45.9084C34.374 46.0944 34.2431 46.2652 34.057 46.2898Z"
            fill="white"
          />
          <path
            d="M32.8778 46.4457C32.6918 46.4703 32.521 46.3394 32.4964 46.1534C32.4719 45.9673 32.6027 45.7966 32.7888 45.772C32.9748 45.7474 33.1456 45.8783 33.1702 46.0643C33.1947 46.2504 33.0638 46.4211 32.8778 46.4457Z"
            fill="white"
          />
          <path
            d="M35.9461 54.9246C36.0221 55.4999 35.755 57.0693 35.1798 57.1454C34.6045 57.2214 33.939 55.7751 33.863 55.1998C33.787 54.6246 34.3293 55.1382 34.9045 55.0622C35.4798 54.9862 35.8701 54.3494 35.9461 54.9246Z"
            fill="#2E2F5E"
          />
        </g>
        <g :class="getClassesGroup('equal-donation')">
          <path
            d="M84.746 49.2698V56.2664C84.746 62.0318 80.0722 66.7056 74.3068 66.7056H66.7781C61.0127 66.7056 56.3389 62.0318 56.3389 56.2664V49.2698C56.3389 43.5044 61.0127 38.8306 66.7781 38.8306H74.3068C80.0722 38.8306 84.746 43.5044 84.746 49.2698Z"
            fill="#FFBE58"
          />
          <path
            d="M79.5366 56.832C79.5366 57.8844 79.0661 60.3479 78.4856 60.3479C77.9051 60.3479 77.4345 57.8844 77.4345 56.832C77.4345 55.7795 77.9051 56.449 78.4856 56.449C79.066 56.449 79.5366 55.7795 79.5366 56.832Z"
            fill="#2E2F5E"
          />
          <path
            d="M76.9788 52.3137C75.8067 52.3137 74.8564 50.3636 74.8564 47.958C74.8564 45.5523 75.8067 43.6022 76.9788 43.6022C78.151 43.6022 79.1012 45.5523 79.1012 47.958C79.1012 50.3636 78.151 52.3137 76.9788 52.3137Z"
            fill="white"
          />
          <path
            d="M80.1197 52.3137C78.9476 52.3137 77.9973 50.3636 77.9973 47.958C77.9973 45.5523 78.9476 43.6022 80.1197 43.6022C81.2919 43.6022 82.2421 45.5523 82.2421 47.958C82.2421 50.3636 81.2919 52.3137 80.1197 52.3137Z"
            fill="white"
          />
          <path
            d="M78.0699 47.9778C77.6671 47.9778 77.3406 47.3486 77.3406 46.5725C77.3406 45.7964 77.6671 45.1672 78.0699 45.1672C78.4727 45.1672 78.7992 45.7964 78.7992 46.5725C78.7992 47.3486 78.4727 47.9778 78.0699 47.9778Z"
            fill="#2E2F5E"
          />
          <path
            d="M79.2708 47.9778C78.8681 47.9778 78.5416 47.3486 78.5416 46.5725C78.5416 45.7964 78.8681 45.1672 79.2708 45.1672C79.6736 45.1672 80.0001 45.7964 80.0001 46.5725C80.0001 47.3486 79.6736 47.9778 79.2708 47.9778Z"
            fill="#2E2F5E"
          />
        </g>
        <g :class="getClassesGroup('equal-donation')">
          <path
            d="M102 54.6237L102 69.0505C102 74.3655 106.316 78.6741 111.64 78.6741C116.963 78.6741 121.279 74.3655 121.279 69.0505L121.279 54.6237C121.279 49.3087 116.963 45 111.64 45C106.316 45 102 49.3087 102 54.6237Z"
            fill="#F45554"
          />
          <path
            d="M112.187 52.118C112.985 52.118 113.633 51.1962 113.633 50.059C113.633 48.9219 112.985 48 112.187 48C111.388 48 110.741 48.9219 110.741 50.059C110.741 51.1962 111.388 52.118 112.187 52.118Z"
            fill="white"
          />
          <path
            d="M109.446 52.118C110.244 52.118 110.892 51.1962 110.892 50.059C110.892 48.9219 110.244 48 109.446 48C108.647 48 108 48.9219 108 50.059C108 51.1962 108.647 52.118 109.446 52.118Z"
            fill="white"
          />
          <path
            d="M110.261 49.7751C110.458 49.7751 110.617 49.4931 110.617 49.1453C110.617 48.7975 110.458 48.5155 110.261 48.5155C110.065 48.5155 109.905 48.7975 109.905 49.1453C109.905 49.4931 110.065 49.7751 110.261 49.7751Z"
            fill="#2E2F5E"
          />
          <path
            d="M111.429 49.7751C111.625 49.7751 111.785 49.4931 111.785 49.1453C111.785 48.7975 111.625 48.5155 111.429 48.5155C111.232 48.5155 111.073 48.7975 111.073 49.1453C111.073 49.4931 111.232 49.7751 111.429 49.7751Z"
            fill="#2E2F5E"
          />
          <path
            d="M110.325 48.879C110.415 48.879 110.488 48.8059 110.488 48.7158C110.488 48.6256 110.415 48.5526 110.325 48.5526C110.235 48.5526 110.162 48.6256 110.162 48.7158C110.162 48.8059 110.235 48.879 110.325 48.879Z"
            fill="white"
          />
          <path
            d="M111.54 48.879C111.63 48.879 111.704 48.8059 111.704 48.7158C111.704 48.6256 111.63 48.5526 111.54 48.5526C111.45 48.5526 111.377 48.6256 111.377 48.7158C111.377 48.8059 111.45 48.879 111.54 48.879Z"
            fill="white"
          />
          <path
            d="M110.102 57.9465C110.102 58.999 109.631 61.4625 109.051 61.4625C108.471 61.4625 108 58.999 108 57.9465C108 56.8941 108.471 57.5635 109.051 57.5635C109.631 57.5635 110.102 56.8941 110.102 57.9465Z"
            fill="#2E2F5E"
          />
        </g>
        <g :class="getClassesGroup('min-donation')">
          <path
            d="M85.6639 72.2027V76.3426C85.6639 81.4718 89.822 85.6299 94.9512 85.6299H102.408C107.538 85.6299 111.696 81.4718 111.696 76.3426V72.2027C111.696 67.0734 107.538 62.9153 102.408 62.9153H94.9512C89.822 62.9153 85.6639 67.0734 85.6639 72.2027Z"
            fill="#D988E3"
          />
          <path
            d="M105.977 72.8299C107.293 72.8299 108.36 71.3107 108.36 69.4368C108.36 67.5628 107.293 66.0436 105.977 66.0436C104.661 66.0436 103.594 67.5628 103.594 69.4368C103.594 71.3107 104.661 72.8299 105.977 72.8299Z"
            fill="white"
          />
          <path
            d="M102.129 72.8299C103.445 72.8299 104.512 71.3107 104.512 69.4368C104.512 67.5628 103.445 66.0436 102.129 66.0436C100.813 66.0436 99.7457 67.5628 99.7457 69.4368C99.7457 71.3107 100.813 72.8299 102.129 72.8299Z"
            fill="white"
          />
          <path
            d="M104.94 69.532C105.341 69.532 105.667 68.9565 105.667 68.2467C105.667 67.5368 105.341 66.9614 104.94 66.9614C104.538 66.9614 104.212 67.5368 104.212 68.2467C104.212 68.9565 104.538 69.532 104.94 69.532Z"
            fill="#2E2F5E"
          />
          <path
            d="M103.031 69.532C103.433 69.532 103.758 68.9565 103.758 68.2467C103.758 67.5368 103.433 66.9614 103.031 66.9614C102.629 66.9614 102.303 67.5368 102.303 68.2467C102.303 68.9565 102.629 69.532 103.031 69.532Z"
            fill="#2E2F5E"
          />
          <path
            d="M103.265 67.7326C103.478 67.7326 103.651 67.5599 103.651 67.347C103.651 67.134 103.478 66.9614 103.265 66.9614C103.052 66.9614 102.879 67.134 102.879 67.347C102.879 67.5599 103.052 67.7326 103.265 67.7326Z"
            fill="white"
          />
          <path
            d="M105.192 67.7326C105.405 67.7326 105.578 67.5599 105.578 67.347C105.578 67.134 105.405 66.9614 105.192 66.9614C104.979 66.9614 104.807 67.134 104.807 67.347C104.807 67.5599 104.979 67.7326 105.192 67.7326Z"
            fill="white"
          />
          <path
            d="M103.182 76.5795C103.182 77.2536 102.829 78.8314 102.393 78.8314C101.957 78.8314 101.603 77.2536 101.603 76.5795C101.603 75.9054 101.957 76.3342 102.393 76.3342C102.829 76.3342 103.182 75.9054 103.182 76.5795Z"
            fill="#2E2F5E"
          />
        </g>
        <g :class="getClassesGroup('equal-donation')">
          <path
            d="M167.164 52.928L167.353 63.6876C167.353 65.6315 167.04 67.5564 166.432 69.3523C165.823 71.1483 164.931 72.7802 163.807 74.1548C162.683 75.5294 161.348 76.6198 159.879 77.3638C158.41 78.1077 156.835 78.4906 155.245 78.4906C153.655 78.4909 152.08 78.1082 150.611 77.3644C149.141 76.6206 147.806 75.5302 146.682 74.1555C145.557 72.7809 144.665 71.1489 144.057 69.3528C143.448 67.5567 143.135 65.6317 143.135 63.6876L142.947 52.928C142.947 49.0025 144.223 45.2379 146.493 42.4622C148.764 39.6865 151.844 38.1271 155.055 38.1271V38.1271C158.266 38.1271 161.346 39.6864 163.617 42.462C165.888 45.2377 167.164 49.0023 167.164 52.928Z"
            fill="#E882E8"
          />
          <path
            d="M156.608 49.4424C157.495 49.4424 158.213 48.4676 158.213 47.2652C158.213 46.0627 157.495 45.0879 156.608 45.0879C155.722 45.0879 155.003 46.0627 155.003 47.2652C155.003 48.4676 155.722 49.4424 156.608 49.4424Z"
            fill="white"
          />
          <path
            d="M153.205 49.4424C154.092 49.4424 154.811 48.4676 154.811 47.2652C154.811 46.0627 154.092 45.0879 153.205 45.0879C152.319 45.0879 151.6 46.0627 151.6 47.2652C151.6 48.4676 152.319 49.4424 153.205 49.4424Z"
            fill="white"
          />
          <path
            d="M155.855 47.3464C156.185 47.3464 156.453 46.962 156.453 46.4878C156.453 46.0136 156.185 45.6292 155.855 45.6292C155.525 45.6292 155.258 46.0136 155.258 46.4878C155.258 46.962 155.525 47.3464 155.855 47.3464Z"
            fill="#2E2F5E"
          />
          <path
            d="M152.932 47.3537C153.262 47.3537 153.53 46.9693 153.53 46.4951C153.53 46.021 153.262 45.6366 152.932 45.6366C152.602 45.6366 152.334 46.021 152.334 46.4951C152.334 46.9693 152.602 47.3537 152.932 47.3537Z"
            fill="#2E2F5E"
          />
          <path
            d="M153.976 54.554C153.976 54.9936 154.332 56.1439 154.772 56.1439C155.211 56.1439 155.566 54.9871 155.566 54.554C155.566 54.1209 155.21 54.554 154.772 54.554C154.334 54.554 153.976 54.1157 153.976 54.554Z"
            fill="#2E2F5E"
          />
          <path
            d="M152.656 46.2792C152.833 46.2792 152.977 46.1353 152.977 45.9579C152.977 45.7804 152.833 45.6366 152.656 45.6366C152.478 45.6366 152.334 45.7804 152.334 45.9579C152.334 46.1353 152.478 46.2792 152.656 46.2792Z"
            fill="white"
          />
          <path
            d="M156.133 46.2281C156.311 46.2281 156.454 46.0842 156.454 45.9068C156.454 45.7293 156.311 45.5855 156.133 45.5855C155.956 45.5855 155.812 45.7293 155.812 45.9068C155.812 46.0842 155.956 46.2281 156.133 46.2281Z"
            fill="white"
          />
        </g>
        <g :class="getClassesGroup('equal-donation')">
          <path
            d="M159.89 69.3521L161.225 52.345C161.716 46.0793 167.194 41.3986 173.46 41.8902C179.725 42.3818 184.406 47.8596 183.914 54.1253L182.58 71.1324C182.088 77.398 176.611 82.0788 170.345 81.5872C164.079 81.0956 159.399 75.6177 159.89 69.3521Z"
            fill="#49D38A"
          />
          <path
            d="M164.587 56.5877C163.644 56.5877 162.88 55.0196 162.88 53.0853C162.88 51.151 163.644 49.583 164.587 49.583C165.529 49.583 166.293 51.151 166.293 53.0853C166.293 55.0196 165.529 56.5877 164.587 56.5877Z"
            fill="white"
          />
          <path
            d="M168.319 56.5877C167.376 56.5877 166.612 55.0196 166.612 53.0853C166.612 51.151 167.376 49.583 168.319 49.583C169.261 49.583 170.026 51.151 170.026 53.0853C170.026 55.0196 169.261 56.5877 168.319 56.5877Z"
            fill="white"
          />
          <path
            d="M165.165 53.2038C164.719 53.2038 164.358 52.5075 164.358 51.6486C164.358 50.7897 164.719 50.0934 165.165 50.0934C165.611 50.0934 165.972 50.7897 165.972 51.6486C165.972 52.5075 165.611 53.2038 165.165 53.2038Z"
            fill="#2E2F5E"
          />
          <path
            d="M167.777 53.2038C167.332 53.2038 166.97 52.5075 166.97 51.6486C166.97 50.7897 167.332 50.0934 167.777 50.0934C168.223 50.0934 168.584 50.7897 168.584 51.6486C168.584 52.5075 168.223 53.2038 167.777 53.2038Z"
            fill="#2E2F5E"
          />
          <path
            d="M163.696 60.238C163.696 61.0336 164.052 62.8959 164.491 62.8959C164.929 62.8959 165.286 61.0336 165.286 60.238C165.286 59.4424 164.93 59.9475 164.491 59.9475C164.051 59.9475 163.696 59.4424 163.696 60.238Z"
            fill="#2E2F5E"
          />
          <path
            d="M167.348 50.889C167.135 50.889 166.963 50.7164 166.963 50.5035C166.963 50.2905 167.135 50.1179 167.348 50.1179C167.561 50.1179 167.734 50.2905 167.734 50.5035C167.734 50.7164 167.561 50.889 167.348 50.889Z"
            fill="white"
          />
          <path
            d="M164.905 50.889C164.692 50.889 164.52 50.7164 164.52 50.5035C164.52 50.2905 164.692 50.1179 164.905 50.1179C165.118 50.1179 165.291 50.2905 165.291 50.5035C165.291 50.7164 165.118 50.889 164.905 50.889Z"
            fill="white"
          />
        </g>
        <g :class="getClassesGroup('lower-donation')">
          <path
            d="M68.5451 68.4588L67.2106 51.4517C66.719 45.1861 61.2412 40.5053 54.9755 40.9969C48.7099 41.4885 44.0292 46.9664 44.5208 53.232L45.8552 70.2392C46.3468 76.5048 51.8246 81.1856 58.0903 80.6939C64.3559 80.2023 69.0367 74.7245 68.5451 68.4588Z"
            fill="#F45554"
          />
          <path
            d="M63.8486 55.6945C64.7913 55.6945 65.5554 54.1264 65.5554 52.1921C65.5554 50.2578 64.7913 48.6897 63.8486 48.6897C62.9059 48.6897 62.1418 50.2578 62.1418 52.1921C62.1418 54.1264 62.9059 55.6945 63.8486 55.6945Z"
            fill="white"
          />
          <path
            d="M60.1164 55.6945C61.0591 55.6945 61.8233 54.1264 61.8233 52.1921C61.8233 50.2578 61.0591 48.6897 60.1164 48.6897C59.1738 48.6897 58.4096 50.2578 58.4096 52.1921C58.4096 54.1264 59.1738 55.6945 60.1164 55.6945Z"
            fill="white"
          />
          <path
            d="M63.2702 52.3105C63.716 52.3105 64.0773 51.6142 64.0773 50.7553C64.0773 49.8964 63.716 49.2002 63.2702 49.2002C62.8244 49.2002 62.463 49.8964 62.463 50.7553C62.463 51.6142 62.8244 52.3105 63.2702 52.3105Z"
            fill="#2E2F5E"
          />
          <path
            d="M60.6579 52.3105C61.1037 52.3105 61.465 51.6142 61.465 50.7553C61.465 49.8964 61.1037 49.2002 60.6579 49.2002C60.2121 49.2002 59.8507 49.8964 59.8507 50.7553C59.8507 51.6142 60.2121 52.3105 60.6579 52.3105Z"
            fill="#2E2F5E"
          />
          <path
            d="M64.739 59.3448C64.739 60.1403 64.3829 62.0027 63.9447 62.0027C63.5064 62.0027 63.1491 60.1403 63.1491 59.3448C63.1491 58.5492 63.5051 59.0543 63.9447 59.0543C64.3842 59.0543 64.739 58.5492 64.739 59.3448Z"
            fill="#2E2F5E"
          />
          <path
            d="M61.087 49.9958C61.3 49.9958 61.4726 49.8232 61.4726 49.6102C61.4726 49.3973 61.3 49.2246 61.087 49.2246C60.8741 49.2246 60.7015 49.3973 60.7015 49.6102C60.7015 49.8232 60.8741 49.9958 61.087 49.9958Z"
            fill="white"
          />
          <path
            d="M63.5299 49.9958C63.7429 49.9958 63.9155 49.8232 63.9155 49.6102C63.9155 49.3973 63.7429 49.2246 63.5299 49.2246C63.317 49.2246 63.1443 49.3973 63.1443 49.6102C63.1443 49.8232 63.317 49.9958 63.5299 49.9958Z"
            fill="white"
          />
        </g>
        <g :class="getClassesGroup('lower-donation')">
          <path
            d="M136.131 61.8905L136.131 76.3173C136.131 81.6323 140.447 85.9409 145.771 85.9409C151.095 85.9409 155.41 81.6323 155.41 76.3173L155.41 61.8905C155.41 56.5755 151.095 52.2668 145.771 52.2668C140.447 52.2668 136.131 56.5755 136.131 61.8905Z"
            fill="#4C40CF"
          />
          <path
            d="M143.336 61.1403C144.135 61.1403 144.782 60.2185 144.782 59.0813C144.782 57.9441 144.135 57.0223 143.336 57.0223C142.538 57.0223 141.89 57.9441 141.89 59.0813C141.89 60.2185 142.538 61.1403 143.336 61.1403Z"
            fill="white"
          />
          <path
            d="M140.595 61.1403C141.394 61.1403 142.041 60.2185 142.041 59.0813C142.041 57.9441 141.394 57.0223 140.595 57.0223C139.797 57.0223 139.149 57.9441 139.149 59.0813C139.149 60.2185 139.797 61.1403 140.595 61.1403Z"
            fill="white"
          />
          <path
            d="M141.411 58.7974C141.607 58.7974 141.767 58.5154 141.767 58.1676C141.767 57.8198 141.607 57.5378 141.411 57.5378C141.214 57.5378 141.055 57.8198 141.055 58.1676C141.055 58.5154 141.214 58.7974 141.411 58.7974Z"
            fill="#2E2F5E"
          />
          <path
            d="M142.578 58.7974C142.775 58.7974 142.934 58.5154 142.934 58.1676C142.934 57.8198 142.775 57.5378 142.578 57.5378C142.381 57.5378 142.222 57.8198 142.222 58.1676C142.222 58.5154 142.381 58.7974 142.578 58.7974Z"
            fill="#2E2F5E"
          />
          <path
            d="M141.474 57.9013C141.564 57.9013 141.637 57.8282 141.637 57.7381C141.637 57.6479 141.564 57.5748 141.474 57.5748C141.384 57.5748 141.311 57.6479 141.311 57.7381C141.311 57.8282 141.384 57.9013 141.474 57.9013Z"
            fill="white"
          />
          <path
            d="M142.69 57.9013C142.78 57.9013 142.853 57.8282 142.853 57.7381C142.853 57.6479 142.78 57.5748 142.69 57.5748C142.6 57.5748 142.526 57.6479 142.526 57.7381C142.526 57.8282 142.6 57.9013 142.69 57.9013Z"
            fill="white"
          />
          <path
            d="M144.233 65.2133C144.233 66.2657 143.763 68.7292 143.182 68.7292C142.602 68.7292 142.131 66.2657 142.131 65.2133C142.131 64.1609 142.602 64.8303 143.182 64.8303C143.763 64.8303 144.233 64.1608 144.233 65.2133Z"
            fill="#2E2F5E"
          />
        </g>
        <g :class="getClassesGroup('min-donation')">
          <path
            d="M79.8777 63.1132H62.8183C56.5334 63.1132 51.4385 68.2081 51.4385 74.493C51.4385 80.7779 56.5334 85.8728 62.8183 85.8728H79.8777C86.1626 85.8728 91.2575 80.7779 91.2575 74.493C91.2575 68.2081 86.1626 63.1132 79.8777 63.1132Z"
            fill="#49D38A"
          />
          <path
            d="M84.2339 71.9598C85.1766 71.9598 85.9408 70.8987 85.9408 69.5898C85.9408 68.2808 85.1766 67.2197 84.2339 67.2197C83.2913 67.2197 82.5271 68.2808 82.5271 69.5898C82.5271 70.8987 83.2913 71.9598 84.2339 71.9598Z"
            fill="white"
          />
          <path
            d="M80.3749 71.9598C81.3175 71.9598 82.0817 70.8987 82.0817 69.5898C82.0817 68.2808 81.3175 67.2197 80.3749 67.2197C79.4322 67.2197 78.668 68.2808 78.668 69.5898C78.668 70.8987 79.4322 71.9598 80.3749 71.9598Z"
            fill="white"
          />
          <path
            d="M83.6578 69.1476C84.0596 69.1476 84.3853 68.8121 84.3853 68.3983C84.3853 67.9845 84.0596 67.649 83.6578 67.649C83.256 67.649 82.9303 67.9845 82.9303 68.3983C82.9303 68.8121 83.256 69.1476 83.6578 69.1476Z"
            fill="#2E2F5E"
          />
          <path
            d="M80.9643 69.1476C81.3661 69.1476 81.6918 68.8121 81.6918 68.3983C81.6918 67.9845 81.3661 67.649 80.9643 67.649C80.5626 67.649 80.2369 67.9845 80.2369 68.3983C80.2369 68.8121 80.5626 69.1476 80.9643 69.1476Z"
            fill="#2E2F5E"
          />
          <path
            d="M84.7579 74.6872C84.7579 75.7397 84.2873 78.2032 83.7068 78.2032C83.1263 78.2032 82.6558 75.7397 82.6558 74.6872C82.6558 73.6348 83.1263 74.3042 83.7068 74.3042C84.2873 74.3042 84.7579 73.6348 84.7579 74.6872Z"
            fill="#2E2F5E"
          />
          <path
            d="M81.2675 68.3994C81.4698 68.3994 81.6338 68.2503 81.6338 68.0665C81.6338 67.8827 81.4698 67.7336 81.2675 67.7336C81.0652 67.7336 80.9012 67.8827 80.9012 68.0665C80.9012 68.2503 81.0652 68.3994 81.2675 68.3994Z"
            fill="white"
          />
          <path
            d="M84.0044 68.3994C84.2067 68.3994 84.3707 68.2503 84.3707 68.0665C84.3707 67.8827 84.2067 67.7336 84.0044 67.7336C83.8021 67.7336 83.6381 67.8827 83.6381 68.0665C83.6381 68.2503 83.8021 68.3994 84.0044 68.3994Z"
            fill="white"
          />
        </g>
        <g :class="getClassesGroup('lower-donation')">
          <path
            d="M61.6087 69.7361L61.75 76.3372C61.7502 77.5298 61.5155 78.7107 61.0592 79.8126C60.6029 80.9144 59.9341 81.9156 59.0909 82.7589C58.2477 83.6022 57.2466 84.2712 56.1448 84.7276C55.043 85.1841 53.8622 85.419 52.6696 85.419C51.4769 85.4191 50.2959 85.1843 49.194 84.728C48.092 84.2717 47.0908 83.6027 46.2475 82.7594C45.4041 81.916 44.7352 80.9148 44.2788 79.8129C43.8225 78.7109 43.5877 77.5299 43.5879 76.3372L43.4465 69.7361C43.4465 67.3278 44.4032 65.0181 46.1061 63.3152C47.809 61.6123 50.1186 60.6556 52.5269 60.6556V60.6556C54.9353 60.6556 57.2451 61.6123 58.9482 63.3151C60.6513 65.018 61.6083 67.3277 61.6087 69.7361Z"
            fill="#FFBE58"
          />
          <path
            d="M54.2915 69.1551C55.1781 69.1551 55.8968 68.1803 55.8968 66.9778C55.8968 65.7754 55.1781 64.8006 54.2915 64.8006C53.4049 64.8006 52.6862 65.7754 52.6862 66.9778C52.6862 68.1803 53.4049 69.1551 54.2915 69.1551Z"
            fill="white"
          />
          <path
            d="M50.8896 69.1551C51.7761 69.1551 52.4949 68.1803 52.4949 66.9778C52.4949 65.7754 51.7761 64.8006 50.8896 64.8006C50.003 64.8006 49.2842 65.7754 49.2842 66.9778C49.2842 68.1803 50.003 69.1551 50.8896 69.1551Z"
            fill="white"
          />
          <path
            d="M55.309 67.0662C55.6391 67.0662 55.9067 66.6818 55.9067 66.2076C55.9067 65.7334 55.6391 65.349 55.309 65.349C54.9789 65.349 54.7113 65.7334 54.7113 66.2076C54.7113 66.6818 54.9789 67.0662 55.309 67.0662Z"
            fill="#2E2F5E"
          />
          <path
            d="M51.5777 67.0588C51.9077 67.0588 52.1753 66.6744 52.1753 66.2003C52.1753 65.7261 51.9077 65.3417 51.5777 65.3417C51.2476 65.3417 50.98 65.7261 50.98 66.2003C50.98 66.6744 51.2476 67.0588 51.5777 67.0588Z"
            fill="#2E2F5E"
          />
          <path
            d="M51.6596 74.2666C51.6596 74.7061 52.0156 75.8564 52.4552 75.8564C52.8948 75.8564 53.2495 74.6997 53.2495 74.2666C53.2495 73.8334 52.8935 74.2666 52.4552 74.2666C52.0169 74.2666 51.6596 73.8283 51.6596 74.2666Z"
            fill="#2E2F5E"
          />
          <path
            d="M51.76 65.9404C51.9375 65.9404 52.0813 65.7966 52.0813 65.6191C52.0813 65.4417 51.9375 65.2978 51.76 65.2978C51.5826 65.2978 51.4387 65.4417 51.4387 65.6191C51.4387 65.7966 51.5826 65.9404 51.76 65.9404Z"
            fill="white"
          />
          <path
            d="M55.0327 65.9917C55.2101 65.9917 55.354 65.8478 55.354 65.6703C55.354 65.4929 55.2101 65.349 55.0327 65.349C54.8552 65.349 54.7113 65.4929 54.7113 65.6703C54.7113 65.8478 54.8552 65.9917 55.0327 65.9917Z"
            fill="white"
          />
        </g>
        <g :class="getClassesGroup('min-donation')">
          <path
            d="M121.993 72.8144V78.1059C121.993 82.4657 125.528 86 129.887 86H135.581C139.941 86 143.475 82.4657 143.475 78.1059V72.8144C143.475 68.4546 139.941 64.9202 135.581 64.9202H129.887C125.528 64.9202 121.993 68.4546 121.993 72.8144Z"
            fill="#49D38A"
          />
          <path
            d="M125.933 78.534C125.933 79.3296 126.289 81.1932 126.727 81.1932C127.165 81.1932 127.523 79.3296 127.523 78.534C127.523 77.7384 127.167 78.2448 126.727 78.2448C126.287 78.2448 125.933 77.7384 125.933 78.534Z"
            fill="#2E2F5E"
          />
          <path
            d="M127.867 75.1178C128.754 75.1178 129.473 73.6429 129.473 71.8236C129.473 70.0043 128.754 68.5295 127.867 68.5295C126.981 68.5295 126.262 70.0043 126.262 71.8236C126.262 73.6429 126.981 75.1178 127.867 75.1178Z"
            fill="white"
          />
          <path
            d="M125.491 75.1178C126.378 75.1178 127.096 73.6429 127.096 71.8236C127.096 70.0043 126.378 68.5295 125.491 68.5295C124.605 68.5295 123.886 70.0043 123.886 71.8236C123.886 73.6429 124.605 75.1178 125.491 75.1178Z"
            fill="white"
          />
          <path
            d="M127.171 71.0679C127.475 71.0679 127.722 70.592 127.722 70.005C127.722 69.418 127.475 68.9421 127.171 68.9421C126.866 68.9421 126.619 69.418 126.619 70.005C126.619 70.592 126.866 71.0679 127.171 71.0679Z"
            fill="#2E2F5E"
          />
          <path
            d="M126.262 71.0679C126.567 71.0679 126.814 70.592 126.814 70.005C126.814 69.418 126.567 68.9421 126.262 68.9421C125.958 68.9421 125.711 69.418 125.711 70.005C125.711 70.592 125.958 71.0679 126.262 71.0679Z"
            fill="#2E2F5E"
          />
          <path
            d="M126.437 69.5319C126.579 69.5319 126.694 69.4168 126.694 69.2748C126.694 69.1329 126.579 69.0178 126.437 69.0178C126.295 69.0178 126.18 69.1329 126.18 69.2748C126.18 69.4168 126.295 69.5319 126.437 69.5319Z"
            fill="white"
          />
          <path
            d="M127.337 69.5319C127.479 69.5319 127.594 69.4168 127.594 69.2748C127.594 69.1329 127.479 69.0178 127.337 69.0178C127.195 69.0178 127.08 69.1329 127.08 69.2748C127.08 69.4168 127.195 69.5319 127.337 69.5319Z"
            fill="white"
          />
        </g>
        <g :class="getClassesGroup('equal-donation')">
          <path
            d="M19.9798 72.2029V76.3427C19.9798 81.472 24.1379 85.6301 29.2671 85.6301H36.7243C41.8536 85.6301 46.0117 81.472 46.0117 76.3427V72.2029C46.0117 67.0736 41.8536 62.9155 36.7243 62.9155H29.2671C24.1379 62.9155 19.9798 67.0736 19.9798 72.2029Z"
            fill="#9EBCF9"
          />
          <path
            d="M40.294 72.8299C41.61 72.8299 42.6768 71.3108 42.6768 69.4368C42.6768 67.5629 41.61 66.0437 40.294 66.0437C38.9779 66.0437 37.9111 67.5629 37.9111 69.4368C37.9111 71.3108 38.9779 72.8299 40.294 72.8299Z"
            fill="white"
          />
          <path
            d="M36.4445 72.8299C37.7606 72.8299 38.8274 71.3108 38.8274 69.4368C38.8274 67.5629 37.7606 66.0437 36.4445 66.0437C35.1285 66.0437 34.0616 67.5629 34.0616 69.4368C34.0616 71.3108 35.1285 72.8299 36.4445 72.8299Z"
            fill="white"
          />
          <path
            d="M39.2555 69.532C39.6572 69.532 39.9829 68.9566 39.9829 68.2467C39.9829 67.5369 39.6572 66.9615 39.2555 66.9615C38.8537 66.9615 38.528 67.5369 38.528 68.2467C38.528 68.9566 38.8537 69.532 39.2555 69.532Z"
            fill="#2E2F5E"
          />
          <path
            d="M37.346 69.532C37.7478 69.532 38.0735 68.9566 38.0735 68.2467C38.0735 67.5369 37.7478 66.9615 37.346 66.9615C36.9442 66.9615 36.6185 67.5369 36.6185 68.2467C36.6185 68.9566 36.9442 69.532 37.346 69.532Z"
            fill="#2E2F5E"
          />
          <path
            d="M37.5801 67.7326C37.793 67.7326 37.9657 67.56 37.9657 67.3471C37.9657 67.1341 37.793 66.9615 37.5801 66.9615C37.3671 66.9615 37.1945 67.1341 37.1945 67.3471C37.1945 67.56 37.3671 67.7326 37.5801 67.7326Z"
            fill="white"
          />
          <path
            d="M39.508 67.7326C39.721 67.7326 39.8936 67.56 39.8936 67.3471C39.8936 67.1341 39.721 66.9615 39.508 66.9615C39.2951 66.9615 39.1224 67.1341 39.1224 67.3471C39.1224 67.56 39.2951 67.7326 39.508 67.7326Z"
            fill="white"
          />
          <path
            d="M37.4982 76.5796C37.4982 77.2536 37.1447 78.8315 36.7087 78.8315C36.2726 78.8315 35.9192 77.2536 35.9192 76.5796C35.9192 75.9055 36.2726 76.3342 36.7087 76.3342C37.1447 76.3342 37.4982 75.9055 37.4982 76.5796Z"
            fill="#2E2F5E"
          />
        </g>
        <g :class="getClassesGroup('equal-donation')">
          <path
            d="M177.427 71.8203V75.9601C177.427 81.0894 173.269 85.2475 168.14 85.2475H160.683C155.553 85.2475 151.395 81.0894 151.395 75.9601V71.8203C151.395 66.691 155.553 62.5329 160.683 62.5329H168.14C173.269 62.5329 177.427 66.691 177.427 71.8203Z"
            fill="#F45554"
          />
          <path
            d="M157.113 72.4474C155.797 72.4474 154.73 70.9282 154.73 69.0542C154.73 67.1803 155.797 65.6611 157.113 65.6611C158.429 65.6611 159.496 67.1803 159.496 69.0542C159.496 70.9282 158.429 72.4474 157.113 72.4474Z"
            fill="white"
          />
          <path
            d="M160.963 72.4474C159.647 72.4474 158.58 70.9282 158.58 69.0542C158.58 67.1803 159.647 65.6611 160.963 65.6611C162.279 65.6611 163.345 67.1803 163.345 69.0542C163.345 70.9282 162.279 72.4474 160.963 72.4474Z"
            fill="white"
          />
          <path
            d="M158.152 69.1493C157.75 69.1493 157.424 68.5739 157.424 67.8641C157.424 67.1542 157.75 66.5788 158.152 66.5788C158.553 66.5788 158.879 67.1542 158.879 67.8641C158.879 68.5739 158.553 69.1493 158.152 69.1493Z"
            fill="#2E2F5E"
          />
          <path
            d="M160.061 69.1493C159.659 69.1493 159.334 68.5739 159.334 67.8641C159.334 67.1542 159.659 66.5788 160.061 66.5788C160.463 66.5788 160.789 67.1542 160.789 67.8641C160.789 68.5739 160.463 69.1493 160.061 69.1493Z"
            fill="#2E2F5E"
          />
          <path
            d="M159.827 67.35C159.614 67.35 159.441 67.1773 159.441 66.9644C159.441 66.7514 159.614 66.5788 159.827 66.5788C160.04 66.5788 160.213 66.7514 160.213 66.9644C160.213 67.1773 160.04 67.35 159.827 67.35Z"
            fill="white"
          />
          <path
            d="M157.899 67.35C157.686 67.35 157.513 67.1773 157.513 66.9644C157.513 66.7514 157.686 66.5788 157.899 66.5788C158.112 66.5788 158.285 66.7514 158.285 66.9644C158.285 67.1773 158.112 67.35 157.899 67.35Z"
            fill="white"
          />
          <path
            d="M159.909 76.1969C159.909 76.871 160.262 78.4488 160.698 78.4488C161.134 78.4488 161.488 76.871 161.488 76.1969C161.488 75.5228 161.134 75.9516 160.698 75.9516C160.262 75.9516 159.909 75.5228 159.909 76.1969Z"
            fill="#2E2F5E"
          />
        </g>
        <g :class="getClassesGroup('equal-donation')">
          <path
            d="M-8.39233e-05 68.2874L-8.39233e-05 72.4272C-8.39233e-05 77.5565 4.15802 81.7146 9.28729 81.7146H16.7444C21.8737 81.7146 26.0318 77.5565 26.0318 72.4272V68.2874C26.0318 63.1581 21.8737 59 16.7444 59H9.28729C4.15802 59 -8.39233e-05 63.1581 -8.39233e-05 68.2874Z"
            fill="#D988E3"
          />
          <path
            d="M20.3133 68.9146C21.6293 68.9146 22.6962 67.3954 22.6962 65.5215C22.6962 63.6475 21.6293 62.1283 20.3133 62.1283C18.9973 62.1283 17.9304 63.6475 17.9304 65.5215C17.9304 67.3954 18.9973 68.9146 20.3133 68.9146Z"
            fill="white"
          />
          <path
            d="M16.4647 68.9146C17.7807 68.9146 18.8476 67.3954 18.8476 65.5215C18.8476 63.6475 17.7807 62.1283 16.4647 62.1283C15.1487 62.1283 14.0818 63.6475 14.0818 65.5215C14.0818 67.3954 15.1487 68.9146 16.4647 68.9146Z"
            fill="white"
          />
          <path
            d="M19.2756 65.6166C19.6774 65.6166 20.0031 65.0412 20.0031 64.3314C20.0031 63.6215 19.6774 63.0461 19.2756 63.0461C18.8738 63.0461 18.5481 63.6215 18.5481 64.3314C18.5481 65.0412 18.8738 65.6166 19.2756 65.6166Z"
            fill="#2E2F5E"
          />
          <path
            d="M17.367 65.6166C17.7687 65.6166 18.0944 65.0412 18.0944 64.3314C18.0944 63.6215 17.7687 63.0461 17.367 63.0461C16.9652 63.0461 16.6395 63.6215 16.6395 64.3314C16.6395 65.0412 16.9652 65.6166 17.367 65.6166Z"
            fill="#2E2F5E"
          />
          <path
            d="M17.6011 63.8173C17.814 63.8173 17.9866 63.6446 17.9866 63.4317C17.9866 63.2187 17.814 63.0461 17.6011 63.0461C17.3881 63.0461 17.2155 63.2187 17.2155 63.4317C17.2155 63.6446 17.3881 63.8173 17.6011 63.8173Z"
            fill="white"
          />
          <path
            d="M19.5282 63.8173C19.7411 63.8173 19.9138 63.6446 19.9138 63.4317C19.9138 63.2187 19.7411 63.0461 19.5282 63.0461C19.3152 63.0461 19.1426 63.2187 19.1426 63.4317C19.1426 63.6446 19.3152 63.8173 19.5282 63.8173Z"
            fill="white"
          />
          <path
            d="M17.5183 72.6642C17.5183 73.3383 17.1649 74.9161 16.7288 74.9161C16.2928 74.9161 15.9393 73.3383 15.9393 72.6642C15.9393 71.9901 16.2928 72.4189 16.7288 72.4189C17.1648 72.4189 17.5183 71.9901 17.5183 72.6642Z"
            fill="#2E2F5E"
          />
        </g>
        <g :class="getClassesGroup('min-donation')">
          <path
            d="M125.003 69.9856L125.144 76.5868C125.144 77.7793 124.91 78.9602 124.453 80.0621C123.997 81.1639 123.328 82.1651 122.485 83.0084C121.642 83.8518 120.641 84.5207 119.539 84.9772C118.437 85.4336 117.256 85.6685 116.064 85.6685C114.871 85.6687 113.69 85.4339 112.588 84.9775C111.486 84.5212 110.485 83.8522 109.642 83.0089C108.798 82.1655 108.129 81.1643 107.673 80.0624C107.217 78.9605 106.982 77.7794 106.982 76.5868L106.841 69.9856C106.841 67.5773 107.797 65.2677 109.5 63.5648C111.203 61.8618 113.513 60.9052 115.921 60.9052V60.9052C118.329 60.9052 120.639 61.8618 122.342 63.5647C124.045 65.2676 125.002 67.5772 125.003 69.9856Z"
            fill="#FFBE58"
          />
          <path
            d="M117.685 69.4045C118.572 69.4045 119.291 68.4297 119.291 67.2273C119.291 66.0248 118.572 65.05 117.685 65.05C116.799 65.05 116.08 66.0248 116.08 67.2273C116.08 68.4297 116.799 69.4045 117.685 69.4045Z"
            fill="white"
          />
          <path
            d="M114.283 69.4045C115.169 69.4045 115.888 68.4297 115.888 67.2273C115.888 66.0248 115.169 65.05 114.283 65.05C113.396 65.05 112.677 66.0248 112.677 67.2273C112.677 68.4297 113.396 69.4045 114.283 69.4045Z"
            fill="white"
          />
          <path
            d="M116.932 67.3084C117.262 67.3084 117.53 66.924 117.53 66.4498C117.53 65.9756 117.262 65.5912 116.932 65.5912C116.602 65.5912 116.335 65.9756 116.335 66.4498C116.335 66.924 116.602 67.3084 116.932 67.3084Z"
            fill="#2E2F5E"
          />
          <path
            d="M114.971 67.3084C115.301 67.3084 115.569 66.924 115.569 66.4498C115.569 65.9756 115.301 65.5912 114.971 65.5912C114.641 65.5912 114.374 65.9756 114.374 66.4498C114.374 66.924 114.641 67.3084 114.971 67.3084Z"
            fill="#2E2F5E"
          />
          <path
            d="M115.053 74.5162C115.053 74.9558 115.409 76.1061 115.849 76.1061C116.289 76.1061 116.643 74.9493 116.643 74.5162C116.643 74.0831 116.287 74.5162 115.849 74.5162C115.411 74.5162 115.053 74.0779 115.053 74.5162Z"
            fill="#2E2F5E"
          />
          <path
            d="M115.154 66.1901C115.331 66.1901 115.475 66.0462 115.475 65.8688C115.475 65.6913 115.331 65.5475 115.154 65.5475C114.976 65.5475 114.832 65.6913 114.832 65.8688C114.832 66.0462 114.976 66.1901 115.154 66.1901Z"
            fill="white"
          />
          <path
            d="M117.21 66.1901C117.388 66.1901 117.532 66.0462 117.532 65.8688C117.532 65.6913 117.388 65.5475 117.21 65.5475C117.033 65.5475 116.889 65.6913 116.889 65.8688C116.889 66.0462 117.033 66.1901 117.21 66.1901Z"
            fill="white"
          />
        </g>
        <path
          :class="getClassesGroup('higher-donation')"
          d="M54.0499 10.9804C50.9838 9.46123 47.3735 10.8114 45.6367 13.7866L44.9969 14.9238L43.8483 14.2379C40.9536 12.4316 37.0891 12.9677 35.111 15.7599C32.847 18.9657 33.8206 23.4034 37.0599 25.4155L48.2711 32.3435C48.9719 32.8008 49.9349 32.5578 50.3347 31.8227L56.916 20.4046C58.8127 17.0964 57.5639 12.728 54.0499 10.9804Z"
          fill="#F45554"
        />
        <path
          :class="getClassesGroup('higher-donation')"
          d="M145.17 12.1701C148.067 10.7348 151.478 12.0104 153.119 14.8213L153.724 15.8957L154.809 15.2477C157.544 13.5411 161.195 14.0477 163.064 16.6857C165.203 19.7145 164.283 23.9073 161.223 25.8083L150.63 32.3538C149.968 32.7859 149.058 32.5563 148.681 31.8618L142.463 21.074C140.671 17.9484 141.85 13.8212 145.17 12.1701Z"
          fill="#F45554"
        />
        <path
          :style="heartStyle"
          class="voluntary-contribution-illustration__heart"
          d="M109.379 13.6392C106.481 11.1234 102.214 11.6052 99.5273 14.3885L98.5263 15.4591L97.4727 14.3885C94.8387 11.6052 90.5188 11.1234 87.6213 13.6392C84.3024 16.5296 84.1444 21.6682 87.0945 24.7728L97.3147 35.4781C97.9468 36.174 99.0532 36.174 99.6853 35.4781L109.905 24.7728C112.856 21.6682 112.698 16.5296 109.379 13.6392Z"
          fill="#F45554"
        />
      </svg>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'VoluntaryContributionIllustration',
  props: {
    tipSuggestion: Number,
    tipSelected: Number
  },
  data() {
    return {
      donationsStatuses: [
        'no-donation',
        'min-donation',
        'lower-donation',
        'equal-donation',
        'higher-donation'
      ]
    }
  },
  computed: {
    status() {
      switch (true) {
        case this.tipSelected > this.tipSuggestion:
          return this.donationsStatuses[4]
        case this.tipSelected === this.tipSuggestion:
          return this.donationsStatuses[3]
        case this.tipSelected < this.tipSuggestion && this.tipSelected > 100:
          return this.donationsStatuses[2]
        case this.tipSelected <= 100 && this.tipSelected > 0:
          return this.donationsStatuses[1]
        default:
          return this.donationsStatuses[0]
      }
    },
    isNoDonation() {
      return this.status === this.donationsStatuses[0]
    },
    heartStyle() {
      return { '--heart-path': this.heartPath }
    },
    heartPath() {
      switch (this.status) {
        case this.donationsStatuses[4]:
          return 'path("M119.757 3.27835C113.962 -1.75317 105.428 -0.789689 100.055 4.7771L98.0527 6.91817L95.9454 4.7771C90.6773 -0.789689 82.0376 -1.75317 76.2426 3.27835C69.6048 9.05924 69.2887 19.3364 75.189 25.5455L95.6293 46.9562C96.8937 48.3479 99.1063 48.3479 100.371 46.9562L120.811 25.5455C126.711 19.3364 126.395 9.05924 119.757 3.27835Z")'
        case this.donationsStatuses[3]:
          return 'path("M115.631 6.66366C110.802 2.57555 103.69 3.35838 99.2121 7.88139L97.5439 9.62101L95.7879 7.88139C91.3978 3.35838 84.198 2.57555 79.3689 6.66366C73.8373 11.3606 73.5739 19.7108 78.4908 24.7557L95.5245 42.1519C96.5781 43.2827 98.4219 43.2827 99.4755 42.1519L116.509 24.7557C121.426 19.7108 121.163 11.3606 115.631 6.66366Z")'
        case this.donationsStatuses[2]:
          return 'path("M113.505 10.1856C109.642 6.83122 103.952 7.47354 100.37 11.1847L99.0351 12.6121L97.6303 11.1847C94.1182 7.47354 88.3584 6.83122 84.4951 10.1856C80.0699 14.0395 79.8591 20.8909 83.7927 25.0303L97.4196 39.3042C98.2625 40.232 99.7375 40.232 100.58 39.3042L114.207 25.0303C118.141 20.8909 117.93 14.0395 113.505 10.1856Z")'
        case this.donationsStatuses[1]:
          return 'path("M109.379 13.6392C106.481 11.1234 102.214 11.6052 99.5273 14.3885L98.5263 15.4591L97.4727 14.3885C94.8387 11.6052 90.5188 11.1234 87.6213 13.6392C84.3024 16.5296 84.1444 21.6682 87.0945 24.7728L97.3147 35.4781C97.9468 36.174 99.0532 36.174 99.6853 35.4781L109.905 24.7728C112.856 21.6682 112.698 16.5296 109.379 13.6392Z")'
        default:
          return 'path("")'
      }
    }
  },
  methods: {
    getClassesGroup(statusAppears) {
      const className = 'voluntary-contribution-illustration__group'
      const indexAppears = this.donationsStatuses.indexOf(statusAppears)
      const indexCurrent = this.donationsStatuses.indexOf(this.status)

      return indexAppears <= indexCurrent && indexAppears !== -1
        ? `${className} ${className}--visible`
        : className
    }
  }
}
</script>

<style lang="scss" scoped>
.voluntary-contribution-illustration {
  display: block;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.25s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  &__no-donation {
    margin: 0 0 $ha-spacing-large 0;
    padding: $ha-unit $ha-unit * 2;
    background: var(--ha-color-background);
  }

  &__group {
    opacity: 0;
    transition: all 0.25s ease;

    &--visible {
      opacity: 1;
      transition: all 0.25s ease;
    }
  }

  &__heart {
    transition: 0.25s ease;
    d: var(--heart-path);
  }
}
</style>

<template>
  <ha-modal class="ModalContributionSelection" v-on="$listeners" data-testid="contribution-modal">
    <template #header>
      {{ $t('modal.contributionSelection.title') }}
    </template>
    <template #body>
      <p>
        {{ $t(`modal.contributionSelection.intro_1${isCheckout ? '_checkout' : ''}`) }}
      </p>
      <p>
        <strong>{{
          $t(`modal.contributionSelection.intro_2${isCheckout ? '_checkout' : ''}`)
        }}</strong>
      </p>
      <div class="Tip">
        <VoluntaryContributionIllustration
          v-if="tipData.tipSuggestion"
          :tip-suggestion="tipData.tipSuggestion"
          :tip-selected="tipSelectedInCents"
        />
        <h3>{{ $t('modal.contributionSelection.contributeUpTo') }}</h3>
        <input
          v-model="tipSelected"
          :min="minTip"
          :max="maxTipCheckoutIncluded"
          step="0.01"
          type="range"
          :data-ux="`Forms_${metadata.type}${
            isWidget ? 'Widget' : ''
          }_Modal_ContributionSelection_ContributionCursor`"
          @change="onUpdateTip"
        />
        <validation-provider v-slot="{ valid, errors }" rules="min_value:0" name="contribution">
          <ha-form-field :is-valid="valid">
            <div class="Tip--Input">
              <ha-input
                v-model="tipSelected"
                type="number"
                step="0.01"
                :is-valid="!valid ? valid : null"
                :data-ux="`Forms_${metadata.type}${
                  isWidget ? 'Widget' : ''
                }_Modal_ContributionSelection_ContributionAmount`"
                data-testid="input-cv-amount"
                @input="onUpdateTip"
                @keydown="checkEntry"
              />
              <span>€</span>
            </div>
            <template #error> {{ errors[0] }} </template>
          </ha-form-field>
        </validation-provider>
        <ha-checkbox
          v-if="!isCheckout"
          v-model="isTipDisabled"
          class="Tip--Checkbox"
          name="dontTip"
          :data-ux="`Forms_${metadata.type}${
            isWidget ? 'Widget' : ''
          }_Modal_ContributionSelection_NoContribution`"
          data-testid="checkbox-no-contribution"
          @change="onUpdateCheckbox"
        >
          {{ $t('modal.contributionSelection.noContribution') }}
        </ha-checkbox>
        <div v-if="showCatchUp" class="Tip--CatchUp" data-testid="retake-wording">
          <p>
            {{ $t('modal.contributionSelection.catchUpTips.yourSupportTo', [organization.name]) }}
          </p>
          <p>
            {{ $t('modal.contributionSelection.catchUpTips.yourSupportTo2') }}
          </p>
          <p>
            {{ $t('modal.contributionSelection.catchUpTips.yourSupportTo3') }}
          </p>
          <p>
            {{
              $t('modal.contributionSelection.catchUpTips.helpPayBankfee', [bankTransactionCost])
            }}
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <ha-button
        v-if="!showCatchUp"
        :disabled="invalidTipValue"
        :data-ux="`Forms_${metadata.type}${
          isWidget ? 'Widget' : ''
        }_Modal_ContributionSelection_SaveContribution`"
        data-testid="button-save"
        @click="saveTipSelected(tipSelectedInCents)"
      >
        {{ $t('button.save') }}
      </ha-button>
      <div v-else class="Tip--CatchUp--Buttons">
        <ha-button
          variant="outline"
          :data-ux="`Forms_${metadata.type}${
            isWidget ? 'Widget' : ''
          }_Modal_ContributionSelection_SaveContribution`"
          data-testid="button-no-retake"
          @click="saveTipSelected(0, true)"
        >
          {{ $t('modal.contributionSelection.catchUpTips.noThankYou') }}
        </ha-button>
        <ha-button
          :data-ux="`Forms_${metadata.type}${
            isWidget ? 'Widget' : ''
          }_Modal_ContributionSelection_SaveContribution`"
          data-testid="button-validate-retake"
          @click="saveTipSelected(bankTransactionCostInCents, true)"
        >
          {{ $t('modal.contributionSelection.catchUpTips.addTips', [bankTransactionCost]) }}
        </ha-button>
      </div>
    </template>
  </ha-modal>
</template>

<script>
import { HaButton, HaModal, HaCheckbox, HaInput, HaFormField } from '@ha/components'
import { ValidationProvider } from 'vee-validate'
import VoluntaryContributionIllustration from '@/components/voluntary-contributions/partials/VoluntaryContributionIllustration.vue'
import { functions } from '@ha/helpers'
import { checkNumericEntry } from '@/helpers/formUtils'
import useTracking from '@/composables/useTracking'
import useStoreData from '@/composables/useStoreData'
import useFormType from '@/composables/useFormType'

export default {
  name: 'ModalContributionSelection',
  components: {
    HaModal,
    HaInput,
    HaCheckbox,
    HaButton,
    HaFormField,
    VoluntaryContributionIllustration,
    ValidationProvider
  },
  inject: ['isWidget'],
  model: {
    event: 'change'
  },
  props: {
    tipDisabled: {
      type: Boolean,
      required: true
    },
    tipData: {
      type: Object
    },
    selectedTier: {
      type: Object
    },
    realAmount: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup() {
    const { trackingMethods } = useTracking()
    const { cart } = useStoreData()
    const { isCheckout } = useFormType()

    return { trackingMethods, cart, isCheckout }
  },
  data() {
    return {
      tipSelected: this.value
        ? functions.convertToEuros(this.value)
        : functions.convertToEuros(this.tipData.tipSuggestion || 0),
      isTipDisabled: !this.value && !this.tipData.tipSuggestion
    }
  },
  computed: {
    invalidTipValue() {
      return this.tipSelected < 0
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.metadata.organization)
    },
    tipSelectedInCents() {
      return functions.convertToCents(this.tipSelected || 0)
    },
    showCatchUp() {
      return parseFloat(this.tipSelected) === 0 && this.realAmount >= 10000
    },
    bankTransactionCost() {
      return functions.convertToEuros(this.realAmount / 100)
    },
    bankTransactionCostInCents() {
      return functions.convertToCents(this.bankTransactionCost)
    },
    minTip() {
      return this.isCheckout ? 0 : functions.convertToEuros(this.tipData.tipSuggestionMin || 1)
    },
    maxTip() {
      return functions.convertToEuros(this.tipData.tipSuggestionMax || 1000)
    },
    maxTipCheckoutIncluded() {
      return this.isCheckout ? parseFloat(this.maxTip) + 1 : this.maxTip
    }
  },
  methods: {
    onUpdateCheckbox(isDisabled) {
      this.tipSelected = isDisabled
        ? 0
        : functions.convertToEuros(parseFloat(this.tipData.tipSuggestion))
      this.isTipDisabled = isDisabled
    },
    onUpdateTip() {
      this.isTipDisabled = !this.tipSelected || !parseFloat(this.tipSelected)
    },
    saveTipSelected(tipSelected, isCatchUp = false) {
      this.tipSelected = tipSelected
      this.$emit('save-tip', tipSelected)
      this.tipTracking(isCatchUp)
    },
    checkEntry(event) {
      checkNumericEntry(event)
    },
    tipTracking(onCatchUp) {
      this.trackingMethods.onTipsUpdate(
        onCatchUp ? 'Tips Catch Up' : 'Tips Submitted',
        { ...this.tipData, tipChosen: this.tipSelected },
        {
          payment_amount: parseFloat(functions.convertToEuros(this.realAmount)).toFixed(2),
          payment_frequency_type: this.selectedTier?.tierType,
          contribution_default_amount: parseFloat(
            functions.convertToEuros(this.tipData.tipSuggestion)
          ).toFixed(2),
          contribution_amount: parseFloat(functions.convertToEuros(this.tipSelected)).toFixed(2)
        }
      )
    }
  }
}
</script>

<style lang="scss">
.ModalContributionSelection {
  font-size: $ha-font-size-small;

  .HaModal--Wrapper {
    -webkit-overflow-scrolling: touch;
  }

  .Tip {
    width: 100%;
    margin-top: $ha-spacing-big;
    margin-bottom: $ha-spacing-medium;
    text-align: center;

    [type='range'] {
      width: 33%;
      margin-bottom: $ha-spacing-medium;
    }

    &--CatchUp {
      margin-top: $ha-spacing-medium;
      text-align: left;

      p {
        color: var(--ha-color-text);
      }

      &--Buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        @include mediaQuery(600) {
          flex-direction: row;

          button + button {
            margin-top: 0 !important;
            margin-left: $ha-spacing-small;
          }
        }

        button {
          width: 100%;
          margin-left: 0;
        }

        button + button {
          margin-top: $ha-spacing-small;
        }
      }
    }

    &--Input {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: $ha-unit * 12.5; // 100px
      margin: 0 auto $ha-spacing-small;

      input {
        color: var(--ha-color-primary);
        font-weight: $ha-font-weight-semibold;
        text-align: center;
      }

      span {
        margin-left: $ha-spacing-small;
      }
    }

    &--Checkbox {
      margin-top: $ha-spacing-large;
    }
  }
}
</style>
